export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const RESET = 'RESET'

export const ADD_PICKUP_COORDS = 'ADD_PICKUP_COORDS'
export const ADD_PICKUP_FORMATTED_ADDRESS = 'ADD_PICKUP_FORMATTED_ADDRESS'
export const ADD_PICKUP_LOCATION = 'ADD_PICKUP_LOCATION'
export const PICKUP_CLEAR = 'PICKUP_CLEAR'

export const SET_RETURN_LOCATION = 'SET_RETURN_LOCATION'

export const ADD_RETURN_FORMATTED_ADDRESS = 'ADD_RETURN_FORMATTED_ADDRESS'
export const ADD_RETURN_COORDS = 'ADD_RETURN_COORDS'
export const ADD_RETURN_LOCATION = 'ADD_RETURN_LOCATION'
export const RETURN_CLEAR = 'RETURN_CLEAR'

export const CHANGE_CATEGORY = 'CHANGE_CATEGORY'

export const ADD_PICKUP_DATETIME = 'ADD_PICKUP_DATETIME'
export const ADD_RETURN_DATETIME = 'ADD_RETURN_DATETIME'

export const SET_VTYPE = 'SET_VTYPE'
