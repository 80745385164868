import { SET_VTYPE } from '../actions/types'

const initialState = {
    vtype: 'car',
}

export default function sessionReducer(state = initialState, action) {
    switch (action.type) {
    case SET_VTYPE:
        return { ...state, vtype: action.payload }
    default:
        return state
    }
}
