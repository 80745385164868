import { GeoPoint } from './geo'

/**
 * In backend, this is called `AvailabilityApi`
 */
export interface AvailabilityAndPricing {
  availability: Availability
  pricing: Pricing
  searchParameters: SearchParameters
  price: number
}

export interface Availability {
  _id: string
  rentalId: string
  vSize: string
  model: string
  modelDoc: ModelDoc
  photoBlob: null
  photoUrl: string
  pricingStatus: PricingStatus
  rentalName: string
  licenceType: LicenceType
  creditCardNeeded: boolean
  yearsOfLicence: number
  timestamp: Date
  id: string
  notes?: string
}

// export namespace Availability {
//   export function hideRentalName(availability: Availability): Availability {
//     return { ...availability, rentalName: '***' }
//   }
// }

export interface ModelDoc {
  _id: string
  name: string
  brand: string
  brandName: string
  doors: number
  seats: number
  cc: null
  size: string
  transmission: Transmission
  fuel: Fuel
  photoUrl: null | string
  photoBlob: null
  vType: VType
  timestamp: Date
  id: string
}
export namespace ModelDoc {
  export function fullName(model: ModelDoc): string {
      return `${model.brandName} - ${model.name}`
  }
}

export type Fuel = 'Benzine' | 'Diesel' | 'LPG' | 'Gas' | 'Electric' | 'Hybrid'

export type Transmission = 'Auto' | 'Manual'

export type VType = 'Car' | 'Moto' | 'ATV'

export type Category = 'all' | 'cars' | 'motos' | 'atv'

export type PricingStatus = 'NoPricing' | 'InActive' | ' WaitingApproval' | 'Active'

export const LicenceTypeValues = ['AM', 'A1', 'A2', 'A', 'B', 'BE', 'B1'] as const
export type LicenceType = (typeof LicenceTypeValues)[number]

export interface Pricing {
  _id: string
  rentalId: string
  rentalName: string
  seasons: Season[]
  locations: LocationPricing[]
  agesAllowed: AgesAllowed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  agesCharges: any[] //FIXME
  extras: ExtraPricing[]
  insurances: InsurancePricing[]
  kmsPerDay: KmsPerDay[]
  isEnabled: IsEnabled
  officeHours: OfficeHour[]
  timestamp: Date
  id: string
}

export interface AgesAllowed {
  from: number
  to: number
}

export interface ExtraPricing {
  extra: Extra
  premiumPerDay: number
}

export type Extra = 'ExtraDriver' | 'GPS' | 'BabySeat' | 'Disabled' | 'RoofRack' | 'Booster'

export const InsuranceTypeValues = [
    'PartInsurance',
    'FullInsurance',
    'PersonalInsuranceCoverage',
    'ThirdPartyLiability',
] as const
export type InsuranceType = (typeof InsuranceTypeValues)[number]

export interface InsurancePricing {
  insurance: Insurance
  premiumPerDay: number
}

export interface Insurance {
  insuranceType: InsuranceType
  excess: number
}

export interface IsEnabled {
  isApproved: boolean
  isActive: boolean
}

export interface KmsPerDay {
  kmsPerDay: number
  premiumPerDay: number
}

export interface LocationPricing {
  location: RLocationClass
  rangeInMeters: number
  minimumDays: number
  premiumPerDay: number
}

export interface RLocationClass {
  _id: string
  rentalId: string
  rentalName: null
  address: string
  googlePlaceId: string
  plusCode: null
  geoPoint: GeoPoint
  timestamp: Date
  id: string
}

export interface OfficeHour {
  timeRange: TimeRange
  price: number
}

export interface TimeRange {
  from: Time
  to: Time
}

//TODO change to literal type
export type Time = string

export interface Season {
  dateFrom: Date
  dateTo: Date
  pricing: SeasonPricing[]
}

export interface SeasonPricing {
  days: number
  price: number
}

export interface SearchParameters {
  locationId: string
  location: RLocationClass
  startDateTime: Date
  endDateTime: Date
  category: Category
  transmission: string
  seats: number
  fuel: string
  creditCardNeeded: boolean
  rLocationId: string
  rLocation: RLocationClass
  ages: null
  extras: null
  insurances: null
  kmsPerDay: number
  maxDistanceInMeters: number
}
