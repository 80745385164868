import {
    ADD_PICKUP_COORDS,
    ADD_PICKUP_LOCATION,
    ADD_PICKUP_FORMATTED_ADDRESS,
    PICKUP_CLEAR,
    SET_RETURN_LOCATION,
    ADD_RETURN_LOCATION,
    ADD_RETURN_COORDS,
    ADD_RETURN_FORMATTED_ADDRESS,
    RETURN_CLEAR,
    CHANGE_CATEGORY,
    ADD_PICKUP_DATETIME,
    ADD_RETURN_DATETIME,
} from '../actions/types'

const initialState = {
    pickupLocation: null,
    hasReturnLocation: false,
    returnLocation: null,
    category: 'All Cars',
    pickupDateTime: null,
    returnPickupTime: null,
}

export default function sessionReducer(state = initialState, action) {
    switch (action.type) {
    case ADD_PICKUP_LOCATION:
        return { ...state, pickupLocation: { ...state.pickupLocation, ...action.payload } }
    case ADD_PICKUP_COORDS:
        return { ...state, pickupLocation: { ...state.pickupLocation, ...action.payload } }
    case ADD_PICKUP_FORMATTED_ADDRESS:
        return { ...state, pickupLocation: { ...state.pickupLocation, ...action.payload } }
    case PICKUP_CLEAR:
        return { ...state, pickupLocation: null }
    case SET_RETURN_LOCATION:
        return { ...state, hasReturnLocation: action.payload }
    case ADD_RETURN_LOCATION:
        return { ...state, returnLocation: { ...state.returnLocation, ...action.payload } }
    case ADD_RETURN_COORDS:
        return { ...state, returnLocation: { ...state.returnLocation, ...action.payload } }
    case ADD_RETURN_FORMATTED_ADDRESS:
        return { ...state, returnLocation: { ...state.returnLocation, ...action.payload } }
    case RETURN_CLEAR:
        return { ...state, returnLocation: null }
    case CHANGE_CATEGORY:
        return { ...state, category: action.payload }
    case ADD_PICKUP_DATETIME:
        return { ...state, pickupDateTime: action.payload }
    case ADD_RETURN_DATETIME:
        return { ...state, returnPickupTime: action.payload }
    default:
        return state
    }
}
