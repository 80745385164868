import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'next-i18next'

const LanguageSwitcher = ({ toggleNavbar }) => {
    const { i18n } = useTranslation()
    const router = useRouter()

    const changeLanguage = (event) => {
        const newLanguage: string = event.target.value
        i18n.changeLanguage(newLanguage).then(() => console.debug('LnaguageSwitcher:Language changed to', newLanguage), error => console.error('LanguageSwitcher:Error changing language', error))
        const path = router.asPath
        toggleNavbar()
        router.push(path, path, { locale: newLanguage }).then(() => console.debug('LanguageSwitcher:Route changed to', path), error => console.error('LanguageSwitcher:Error changing route', error))
    }

    return (
        <div>
            <select onChange={changeLanguage} value={i18n.language}>
                <option value="en">EN</option>
                <option value="de">DE</option>
                <option value="fr">FR</option>
                <option value="it">IT</option>
            </select>
        </div>
    )
}

export default LanguageSwitcher
