import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client'

const cache = new InMemoryCache()

const defaultOptions: DefaultOptions = {
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

const client = new ApolloClient({
    cache: cache,
    uri: process.env.NEXT_GRAPH_CMS_URI,
    defaultOptions: defaultOptions,
})

export default client